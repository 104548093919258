import { Storage } from "aws-amplify"

export const imageLink = (url = "", noCache = true, type = "public") => {
    const timeNow = performance.now()

    if (!url) {
        return ""
    }
    if (noCache) {
        return `https://community175834-checkmeout.s3.amazonaws.com/${type}/${url}?${timeNow}`
    }
    return `https://community175834-checkmeout.s3.amazonaws.com/${type}/${url}`
}

export const privateImageLink = (imageKey) => {
    return Storage.get(imageKey, {
        level: "private"
    })
}