import React, { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import useMediaQuery from '@mui/material/useMediaQuery'
import Carousel from "react-material-ui-carousel"
import Image from "next/image";
import bannercard from "../../../../public/images/Bannercard.svg"
import { myLoader } from "@/app/utils/custom_loader"
import FAQ from './Faq'
import HomePageRecentReviews from "./HomePageRecentReviews"
import getRecentReviews from "@/app/utils/getRecentReviews"
import ExpandableSearch from "@/app/components/ui-components/ExpandableSearch"


export default function HomePage() {
  // Custom Arrow Component to hide arrows
  const CustomArrow = () => <div style={{ width: 0, height: 0, overflow: 'hidden' }} />;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [recentReviews, setRecentReviews] = useState([]);
  useEffect(() => {
    const fetchRecentReviews = async () => {
      const reviews = await getRecentReviews();
      setRecentReviews(reviews);
    };

    fetchRecentReviews();
  }, []);

  return (
    <>
      {/* banner section */}
      <Box
        sx={{
          backgroundImage: `url(/bannerwhite.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          // minHeight: 'calc(100vh - 96px)',

        }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '1em',
        }}>
          <Container className="main-wrapper">

            <Grid container spacing={3}
              sx={{ height: "100%", justifyContent: 'center', alignItems: 'center', }}>
              <Grid item xs={12} md={5}>
                {/* Content 1 */}
                <Typography
                  variant="h6"
                  component={'h3'}
                  sx={{
                    color: "#343434",
                    fontWeight: "700",
                    fontStyle: "normal",
                    lineHeight: "68px",
                    fontSize: {
                      xs: '40px',
                      sm: '40px',
                      md: '48px',
                      lg: '64px',
                    },
                    lineHeight: {
                      xs: '44px',
                      sm: '46px',
                      md: '55px',
                      lg: '64px',
                    },
                    paddingTop: {
                      xs: '25px',
                      sm: '25px',
                      md: '0px',
                      lg: '0px',
                    },
                  }}
                >
                  <nobr>Real Neighbors</nobr><br /><nobr>Real Reviews</nobr>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#676767",
                    fontWeight: "700",
                    fontStyle: "normal",
                    lineHeight: '22px',
                    fontSize: {
                      xs: '22px',
                      sm: '24px',
                      md: '24px',
                      lg: '28px',
                    },
                    marginTop: {
                      md: '15px',
                    },
                    marginBottom: {
                      xs: '15px',
                      sm: '15px',
                      md: '18px',
                      lg: '40px',
                    },
                  }}
                >
                  Find Businesses you can trust
                </Typography>

                <ExpandableSearch expand={true} />
              </Grid>

              <Grid item xs={12} md={7}
                sx={{
                  textAlign: "end",
                }}
              >
                {/* Content 2 */}
                <Carousel
                  className="bannercarousel"
                  navButtonsAlwaysInvisible={true}
                  // for dots colors
                  indicatorIconButtonProps={{
                    style: {
                      marginTop: '5px',
                      marginBottom: '5px',
                      color: '#CCC',       // 3
                    }
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {
                      color: '#7C50F8',
                      background: '#7C50F8',
                    }
                  }}
                >
                  {
                    [1, 2, 3].map((e, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            height: '100%',
                          }}
                        >
                          <Image
                            alt="slide"
                            src={bannercard}
                            style={{
                              maxWidth: "100%",
                              height: 'auto'
                            }}
                            loader={myLoader}
                            unoptimized={true}
                          />
                        </Box>
                      );
                    })
                  }
                </Carousel>
              </Grid>
            </Grid>
          </Container >
        </Box>
      </Box >

      {/* review section */}
      < Box
        sx={{
          backgroundImage: `url(/fullgradient.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          marginBottom: '2em',
        }
        }   >
        <Container className="main-wrapper">
          <Box sx={{
            textAlign: 'center', maxWidth: '660px', width: '100%',
            margin: '0 auto',
            marginTop: '30px',
          }}>
            <Typography variant="h4" component="h2"
              sx={{
                fontSize: {
                  xs: '32px',
                  sm: '32px',
                  md: '36px',
                  lg: '36px',
                },
                textAlign: 'center',
                fontWeight: '700',
                lineheight: '43px',
                color: '#fff',
              }}>
              Recent Reviews
            </Typography>

            <Typography variant="h4" component="h2"
              sx={{
                fontSize: {
                  xs: '14px',
                  sm: '14px',
                  md: '16px',
                  lg: '16px',
                },
                textAlign: 'center',
                fontWeight: '500',
                lineheight: '22px',
                color: '#fff',
                marginTop: '24px',
                marginBottom: '32px',
              }}>
              Here&apos;s a sneak peek at what our community is buzzing about! Check out these latest reviews
              hot off the press.&nbsp;
              <Link
                href="/auth"
                sx={{
                  textDecoration: 'none',
                  color: 'yellow',
                  fontWeight: 700,
                }
                }>
                Join the community
              </Link>
              &nbsp;and share your own experiences.
            </Typography>
          </Box>

          <Box sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'none',
              lg: 'none',
              xl: 'block',
            },
            marginBottom: '2em',
            width: '100%',
          }}>
            <Grid container
              spacing={1}
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}>
              {recentReviews && recentReviews?.map((review, index) => (
                <Grid key={index} item xs={3}
                  sx={{
                    minWidth: '300px',
                    maxWidth: '450px',
                  }}
                >
                  <HomePageRecentReviews key={index} {...review} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* review slidescard for mobile */}

          <Box sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'flex',
              lg: 'flex',
              xl: 'none',
            },
            justifyContent: 'center',
            marginBottom: '2em',
          }}
          >
            <Carousel
              sx={{ width: '100%' }}
              indicatorContainerProps={{
                style: {
                  marginTop: '20px',
                }
              }}
            >
              {
                recentReviews && recentReviews?.map((review, index) => (
                  <Box key={index} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <HomePageRecentReviews {...review} />
                  </Box>
                ))
              }
            </Carousel>
          </Box>
        </Container>
      </Box >

      {/* FAQ section */}
      < Container className="main-wrapper" >
        <Typography
          variant="h3"
          color={'#343434'}
          lineHeight={'43px'}
          fontSize={'36px'}
          fontWeight={'700'}
          textAlign={'center'}
          marginBottom={'16px'}
        >
        </Typography>
        <FAQ />
      </Container >

    </>
  );
}
