import Rating from '@mui/material/Rating'
import React from 'react'


const CMORating = ({ value = 0, size = "medium", readOnly = false, sx = {}, onChange = () => { }, name = "rating", precision = 0.25 }) => {
    return (
        <Rating
            value={value}
            precision={precision}
            size={size}
            onChange={onChange}
            readOnly={readOnly}
            sx={sx}
            name={name}

        />
    )
}

export default CMORating