"use client"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import HomePage from "./components/ui-components/homepage/HomePage"

export default function Home() {
  return (
    <Grid
      container
      component="main"
      sx={{ height: "100%" }}
      alignItems="stretch"
    >
      <CssBaseline />
      <HomePage />
    </Grid>
  )
}
