import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Grid from '@mui/material/Grid';
import Link from "next/link";
import Typography from "@mui/material/Typography"
import CMORating from "@/app/components/Rating"
import { useRouter } from "next/navigation";
import { useUserData } from "@/app/context/userDetailContext"
import { AUTHENTICATED } from "@/lib/constants"

export default function HomePageRecentReviews({ profile, alias, businessName, rating, businessRating, review, linkToBusiness, category }) {
   const router = useRouter();
   const { authStatus } = useUserData();
   const reviewLink = authStatus === AUTHENTICATED ? linkToBusiness : '/auth'

   return (

      <Box
         sx={{
            background: '#fff',
            borderBottom: "7px solid #2FA2E2",
            borderRadius: '6px',
            height: '350px',
            padding: '1em', // Optional: Add padding inside the Box
            '&:hover': {
               cursor: "pointer",
            },
            minWidth: '350px',
            maxWidth: '450px',
         }}
         onClick={() => {
            router.push(reviewLink);
         }}
      >
         <Grid container spacing={2}>
            <Grid item xs={4}>
               <Avatar
                  sx={{
                     borderRadius: '8px',
                     boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
                     width: '100px',
                     height: '100px',
                     border: ' 3px solid #FFF',
                     background: 'orange',
                     overflow: 'hidden',
                     transition: 'transform 0.3s ease-in-out',
                     '&:hover': {
                        transform: 'scale(1.20)',
                     },
                  }}
                  alt={alias}
                  src={profile}>
                  <Typography
                     sx={{
                        fontSize: '4rem', // Adjust the font size as needed
                        fontWeight: 'bold',
                        color: '#FFF', // Ensure the text color contrasts with the background
                     }}
                  >
                     {alias[0]?.toUpperCase()}
                  </Typography>
               </Avatar>
            </Grid>
            <Grid item xs={8}>
               <Box
                  sx={{
                     paddingLeft: {
                        xs: '1em ',
                        sm: '0px',
                        md: '0px',
                        lg: '0px',
                        xl: '0px',
                     }
                  }}>
                  <Typography variant="h4" sx={{
                     fontWeight: '700',
                     fontSize: '26px',
                     color: '#0C111F',
                  }}>
                     {alias}
                  </Typography>
                  <Typography variant="h4" sx={{
                     fontWeight: '500',
                     fontSize: '14px',
                     color: '#676767',
                  }}>
                     Reviewed {businessName}
                  </Typography>
                  <CMORating value={rating} size="small" />
                  <Typography variant="h2" sx={{
                     fontSize: '26px',
                     fontWeight: '800',
                     color: '#001C30',
                  }}>
                     {businessRating}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={12}>
               <Box
                  sx={{
                     minHeight: '50px',
                     maxHeight: '165px',
                     overflow: 'auto',
                  }}
               >
                  <Typography variant="h4" sx={{
                     color: '#343434',
                     opacity: '0.66',
                     fontSize: '12px',
                     fontWeight: '400',
                     lineHeight: '20px',
                  }}>
                     {review}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={12}>
               <Button sx={{
                  textTransform: 'capitalize',
                  color: '#2FA2E2',
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'flex-start',
                  padding: '0px',
                  '&:hover': {
                     background: 'transparent', // This is the hover effect you want to remove
                  },
                  '&:hover .MuiButton-label': {
                     background: 'transparent', // This will remove the hover effect on the label
                  },
               }} variant="text">
                  <Link
                     href={linkToBusiness}
                     style={{
                        textDecoration: "none",
                        color: "primary"
                     }}
                  >
                     <Typography sx={{
                        color: '#2FA2E2',
                        fontWeight: '700',
                        fontSize: '12px',
                        lineHeight: '15px',
                        textAlign: 'left',
                     }}>
                        Read More...
                     </Typography>
                  </Link>
               </Button>
            </Grid>
            <Grid item xs={12}>
               <Box
                  sx={{
                     width: '100%', // Ensure the Box takes the full width of its container
                     right: '28px',
                  }}
               >
                  <Typography
                     variant="h4"
                     sx={{
                        color: '#999',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '16.169px',
                        fontStyle: 'italic',
                        textAlign: 'right', // Right justify the text
                     }}
                  >
                     {category}
                  </Typography>
               </Box>
            </Grid>
         </Grid>
      </Box>
   )
} 