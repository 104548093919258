import { API } from "aws-amplify"
import { imageLink } from "@/app/utils/imageLinks";
import { businessRoute } from "@/app/routes/routes";
import { useUserData } from '@/app/context/userDetailContext';

const listReviewsSortedByDateTime = /* GraphQL */ `
query listReviewsSortedByDateTime {
  listReviewsByDateTimeOfReview(type: "review", sortDirection: DESC, limit: 3) {
    items {
      dateTimeOfReview
      userData {
        avatar
        alias
        community {
          alias
        }
      }
      Business {
        name
        rating
        id
        category1
        category2
        category3
      }
      stars
      review
    }
  }
}

`

export default async function getRecentReviews() {
  try {
    const response = await API.graphql({
      query: listReviewsSortedByDateTime
    })
    const lastReviews = response.data.listReviewsByDateTimeOfReview.items;
    const recentReviews = lastReviews.map((review, index) => ({
      communityAlias: review?.userData?.community?.alias,
      profile: imageLink(review?.userData?.avatar),
      alias: review?.userData?.alias,
      businessName: review?.Business?.name,
      rating: review?.stars,
      businessRating: review?.businessRating,
      review: review?.review,
      linkToBusiness: businessRoute(review?.userData?.community?.alias, review?.Business?.id, review?.Business.name),
      category: review?.category
    }));
    return recentReviews
  } catch (error) {
    console.error("error", error);
    if (error?.data?.listReviewsSortedByDateTime) {
    }
  }

}


