import React, { useState } from 'react';
import DOMPurify from "isomorphic-dompurify";
import { Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useUserData } from '@/app/context/userDetailContext';

const FaqItem = ({ question, answer, isOpen, onToggle }) => (
  <Box sx={{ borderBottom: '1px solid #e0e0e0', py: 2 }}>
    <Box
      onClick={onToggle}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: '#343434',
          fontSize: { xs: '16px', sm: '18px', md: '20px' },
        }}
      >
        {question}
      </Typography>
      <IconButton size="small">
        {isOpen ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
    </Box>
    {isOpen && (
      <Box
        sx={{
          mt: 2,
          color: '#555',
          fontSize: { xs: '14px', sm: '16px' },
          lineHeight: 1.6,
        }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }}
      />
    )}
  </Box>
);

export default function Faq() {
  const { userData } = useUserData();
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: 'Is membership on CheckMeOut.com really free?',
      answer: 'ABSOLUTELY FREE! Whether you want a community user or a business user account, they are both free.'
    },
    {
      question: 'What is the difference between a community user and a business user?',
      answer: 'A community user can leave reviews, favorite businesses, like and dislike businesses, etc. Basically full user of the website. A business user can manage their business metadata and reply to reviews community users leave.'
    },
    {
      question: 'Why do I need to upload a photo to prove I live in the community?',
      answer: 'That is the key differentiator of CheckMeOut.com. By uploading a photo of your Id, we are limiting 1 account per user.',
    },
    {
      question: 'How is CheckMeOut.com different from Yelp or Google Reviews?',
      answer: "ALL reviews are from verified community users. We don't allow businesses to leave reviews on themselves. This, effectively, ensures only people that live in the community can leave reviews."
    },
    {
      question: 'Can I create a business so I and others can leave a review?',
      answer: "ABSOLUTELY! In fact, we encourage it. You can go to <a href='/" + userData?.community?.alias + "/business/create-business'>ADD BUSINESS</a>. We want to ensure that all businesses in our community are listed on CheckMeOut.com. We want to ensure that all businesses, in our community, are listed."
    },
    {
      question: 'Can you prevent businesses from leaving fake reviews to artificially raise their rating?',
      answer: 'Yes. Since you can only leave a review if you have a verified community user account, there is only 1 review per community user allowed per business.'
    },
    {
      question: "I'm a business owner and my business is already listed. Can I manage my own business on your website?",
      answer: 'Yes, you can. You can claim your business and manage your business metadata and reply to reviews. Simply search for your business and click on the "Claim Business" button.'
    },
    {
      question: "Can I recommend another community so they can use CheckMeOut.com?",
      answer: "Yes, you can. Simply click on the <a href='/recommend-community'>Recommend a New Community</a> button and fill out the form. We will reach out to the community and get them on board."
    }
  ]

  return (
    <Box
      sx={{
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          py: 4,
          backgroundColor: '#1976d2',
          color: '#fff',
          fontWeight: 700,
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
        {faqs.map((faq, index) => (
          <FaqItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onToggle={() => setOpenIndex(openIndex === index ? null : index)}
          />
        ))}
      </Box>
    </Box>
  );
}
